import * as React from "react"
import { Link } from "gatsby"
import Layout, { Head } from "../components/layout"

const PostTemplate = () => {
  const pageContext = {
    title: "post",
    description: "post",
  }
  return (
    <Layout>
      <Head pageContext={pageContext} />
      <h1>
        Hello from a <b>Post</b>
      </h1>
      <p>This page is not created until requested by a user.</p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}
export default PostTemplate
